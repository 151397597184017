<template>
  <!-- <div></div> -->
  <b-card class="mb-0 h-100">
    <h2>Vérification l'adresse email associée à votre compte</h2>

    <div class="d-flex align-items-center justify-content-center">
      <div class="text-center">
        <i class="ri-mail-close-line d-block mx-auto font-7rem" />
        <p>
          Une erreur est survenue lors de la validation de votre email :
          <br />
          <strong> Paramètres invalides ou email déjà validé </strong>
          <b-button
            class="mt-2"
            variant="primary"
            :to="{ path: '/login' }"
            block
            :disabled="invalid"
          >
            Recommencer le processus
          </b-button>

          <!-- <b-button
            class="d-flex align-items-center mt-2 text-center"
            variant="primary"
            :to="{path:'/login'}"
          >
            Recommencer le processus
          </b-button> -->
        </p>
      </div>

      <!-- <div v-if="isLoading">

        <p v-if="isLoading">Vérification en cours...</p>
        <p>         
          <b-spinner
            style="width: 3rem; height: 3rem;"
            class="mx-auto d-block"
          />
        </p>
      </div> -->

      <!-- v-if="!isLoading && isvalid" -->
      <!-- <div
        v-if="!isLoading && isvalid"
        class="text-center"
      >
        <i class="ri-mail-check-line d-block mx-auto font-7rem" />
        <p>
          Votre adresse mail a été verifiée avec succes
        </p>

        <p v-if="canSetPassword">

          Pour définir votre mot de passe, veuillez cliquer ici <br> <br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="()=>{
              this.$router.push({
                name: 'etablissements-agents-create-password-agent',
                params: {
                  email: email,
                },
              })
            }"
          >
            Définir mon mot de passe
          </b-button>

        </p>
        <p v-else>
          Vous pouvez dès à présent vous connecter à la plateforme. 
        </p>

      </div> -->
    </div>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import AppLogo from "@/components/AppLogo.vue";
import RecaptchaWidget from "@/components/RecaptchaWidget.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import passwordValidators from "@/helpers/password-validators";
import PasswordInputField from "@/components/PasswordInputField.vue";

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return {
      requiredStoreModules,
      passwordValidators,
      $v: useVuelidate({ $lazy: true }),
    };
  },
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    PasswordInputField,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: true,
      isvalid: false,
      errorMessage: "",
      email: null,
      canSetPassword: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        // this.verifyEmail()
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  validations() {
    return {
      form: {
        nom_ets: { required },
      },

      recaptchaResponse: {
        isValid: (val) => val && val.type === "success",
      },
    };
  },
  mounted() {},
  methods: {
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_verifyEtablissementEmail: "verifyEtablissementEmail",
    }),

    async verifyEmail() {
      const { email, code } = this.$route.query;
      this.email = email;
      const data = {
        email,
        code,
      };
      this.action_verifyEtablissementEmail(data)
        .then((result) => {
          console.log("result: --------------- ", result);
          this.canSetPassword = result.data.setPassword;

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Verification reussie",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.isvalid = true;
          this.isLoading = false;

          // this.$router.replace({ name: "auth-login" });
          if (!this.canSetPassword)
            this.$router.replace({ name: "auth-login" });
          else {
            this.$router.push({
              name: "etablissements-agents-create-password-agent",
              params: {
                email: email,
              },
            });
          }
        })
        .catch((err) => {
          this.isvalid = false;
          this.isLoading = false;
          this.errorMessage = err.message || "";
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/page-auth.scss';
.font-7rem {
  font-size: 7rem;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Parcourir";
}
// .password-field {
//   position: relative;
//   .invalid-feedback.password-rules {
//     position: absolute;
//     background: white;
//     border: 1px solid;
//     top: -170px;
//     left: 0;
//     z-index: 9;
//     box-shadow: 1px 1px 6px 0px #00000042;
//     border-radius: 5px;
//   }
// }
</style>
